import React from 'react'

import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Hero from "../../../components/hero-our-model"
import Content from "../../../components/new-article-with-image-content"

const NewArticleWithImagePage = () => {
    return (
        <Layout>
            <SEO title="New Article with Image" />
            <Content />
        </Layout>
    )
}

export default NewArticleWithImagePage